<template>
  <div class="mx-6 pb-12">
    <div class="text-center justify-center mt-16 pt-16 mx-auto" style="max-width: 450px">
      <p class="mb-0 font-weight-bold" style="color: #70BAFF">{{$t('prices.payout_title')}}</p>
      <h2>{{$t('prices.payout_header')}}</h2>
      <p class="mx-6">{{$t('prices.payout_text')}}</p>
      <v-img
          max-width="350px"
          src="@/assets/financing.jpg"
          contain
          rounded
          class="rounded mx-auto"
      >
      </v-img>
    </div>
  </div>

</template>

<script>
export default {
  name: "FastPayoutMobile"
}
</script>

<style scoped>

</style>